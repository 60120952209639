import { Button } from "@/components/Button";
import { DesktopMenuLink } from "@/components/layout/Navbar/DesktopMenuLink";
import { NotificationDropdown } from "@/components/Notifications/NotificationDropdown";
import { SiteLogo } from "@/components/SiteLogo";
import { SquareSiteLogo } from "@/components/SquareSiteLogo";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Separator } from "@/components/ui/separator";
import { UserAvatar } from "@/components/Users/UserAvatar";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { routes } from "@/routes";
import { LogOut } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";

export const DesktopMenu = ({ menuItems, getActive }) => {
  const { logOut, user } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <nav
      className={cn(
        "fixed left-0 top-0 z-40 h-screen bg-background border-r border-border text-foreground transition-all duration-300 ease-in-out flex-col items-center justify-between pt-5 hidden lg:flex",
        isExpanded ? "w-64" : "w-20",
      )}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <div className="flex flex-col gap-3 w-full h-[80vh] transition-all">
        {isExpanded ? (
          <SiteLogo className="h-[5vh] mx-auto" linkTo={routes.dashboard} />
        ) : (
          <SquareSiteLogo className="h-[5vh] mx-auto" linkTo={routes.dashboard} />
        )}
        <ScrollArea className="h-[75vh]">
          <nav className="w-full flex flex-col gap-2 px-3 mt-3">
            {menuItems.map((item, index) => {
              return <DesktopMenuLink key={index} menuItem={item} isMenuExpanded={isExpanded} getActive={getActive} />;
            })}
          </nav>
        </ScrollArea>
      </div>
      <div className="w-full h-[18vh] flex flex-col gap-2 px-3 mt-3 justify-center items-center">
        <NotificationDropdown title={isExpanded ? "Powiadomienia" : ""} variant="hover-card" />
        <Separator />
        <div className={cn("flex justify-start items-center gap-1 w-full px-1", isExpanded ? "rounded-md" : "")}>
          <Link
            to={routes.account}
            className={cn(
              "flex justify-start gap-2 items-center rounded-md w-full hover:bg-border box-content py-1 px-1",
            )}
          >
            <UserAvatar user={user} />
            <div className={cn("flex flex-col justify-center items-start", isExpanded ? "block" : "hidden")}>
              <span className="flex flex-col justify-center">
                <p className="font-semibold text-sm">{user.first_name}</p>
                <p className="font-semibold text-sm">{user.last_name}</p>
              </span>
            </div>
          </Link>
          <Button
            leftIcon={<LogOut className="h-5 w-5 flex-shrink-0" />}
            variant="ghost"
            className={cn("px-4 h-12", isExpanded ? "block" : "hidden")}
            onClick={logOut}
          />
        </div>
        <span className="text-xs flex flex-row items-center h-[2vh] transition-all text-muted-foreground font-semibold">
          <p className={cn("whitespace-nowrap mr-1", isExpanded ? "block" : "hidden")}>{i18n.t("Powered by")}</p>
          <a href="https://easecrm.pl">easecrm.pl</a>
        </span>
      </div>
    </nav>
  );
};
