import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { FileCategoryInput } from "@/components/File/Categories/FileCategoryInput";
import { FileUploadInput } from "@/components/forms/FileUploadInput";
import { Form } from "@/components/forms/Form";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { toast } from "@/components/ui/use-toast";
import i18n from "@/i18n";
import { useLeadFilePolicy } from "@/policies/lead/useLeadFilePolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Plus, X } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  file_category_id: Yup.string().required(i18n.t("Pole jest wymagane")),
});

export const ClientFileCreateDialog = () => {
  const queryClient = useQueryClient();
  const { id: leadId } = useParams();
  const clientFilePolicy = useLeadFilePolicy();

  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const defaultValues = {
    file_category_id: undefined,
  };

  const onSubmit = (form) => {
    const data = new FormData();
    for (let i = 0; i < files.length; i++) {
      data.append(`files[${i}]`, files[i]);
    }
    data.append("file_category_id", form.file_category_id);
    uploadClientFilesMutation.mutate({ leadId, data });
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });

  const uploadClientFilesMutation = useMutation({
    mutationFn: apiClient.createLeadFiles,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["lead", leadId], exact: true });
      queryClient.invalidateQueries({ queryKey: ["lead", leadId, "files"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pliki dodane" /> });
        form.reset();
        setFiles([]);
        return setIsOpen(false);
      } else {
        if (res.status === 422) {
          toast({
            title: (
              <ErrorToast title="Błąd z plikiem, upewnij się że wysyłasz plik z odpowiednim rozszerzeniem o rozmiarze maksymalnie 10MB" />
            ),
          });
        } else {
          toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
        }
      }
    },
  });

  if (!clientFilePolicy.create()) return null;

  return (
    <Dialog onOpenChange={setIsOpen} open={isOpen}>
      <DialogTrigger asChild>
        <Button title="Dodaj pliki" leftIcon={<Plus size={20} />} onClick={() => setIsOpen(true)} />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{i18n.t("Dodawanie plików")}</DialogTitle>
          <DialogDescription>{i18n.t("Dodaj pliki do klienta.")}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <div className="flex flex-col gap-4 mb-5">
            <FileCategoryInput />
            <div>
              <Label htmlFor="files" className="flex flex-row items-center mb-1">
                {i18n.t("Pliki")}
              </Label>
              <FileUploadInput onFilesSelect={setFiles} />
            </div>
          </div>
          <DialogFooter>
            <Button title="Dodaj" leftIcon={<Plus size={20} />} isLoading={uploadClientFilesMutation.isPending} />
            <Button
              title="Anuluj"
              type="button"
              leftIcon={<X size={20} />}
              variant="destructive"
              onClick={() => setIsOpen(false)}
            />
          </DialogFooter>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
