import apiClient from "@/api/apiClient";
import { DesktopMenu } from "@/components/layout/Navbar/DesktopMenu";
import { MobileMenu } from "@/components/layout/Navbar/MobileMenu";
import { useAuth } from "@/hooks/useAuth";
import { useApplicationPolicy } from "@/policies/application/useApplicationPolicy";
import { useAuditPolicy } from "@/policies/audit/useAuditPolicy";
import { useBillingPolicy } from "@/policies/billing/useBillingPolicy";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { useFilePolicy } from "@/policies/file/useFilePolicy";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { useClientPolicy } from "@/policies/lead/useClientPolicy";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { useCalculatorPolicy } from "@/policies/useCalculatorPolicy";
import { useDashboardPolicy } from "@/policies/useDashboardPolicy";
import { useDepartmentPolicy } from "@/policies/useDepartmentPolicy";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { useTaskPolicy } from "@/policies/useTaskPolicy";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { useWarehousePolicy } from "@/policies/warehouse/useWarehousePolicy";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import {
  Boxes,
  Calculator,
  Calendar,
  CircleCheck,
  CircleDollarSign,
  ClipboardList,
  Download,
  FileSignature,
  FileUser,
  Package,
  PackageSearch,
  PieChart,
  Settings,
  User,
  UserCheck,
  Users,
  Wrench,
} from "lucide-react";
import { useEffect, useState } from "react";
import { FaFilterCircleDollar } from "react-icons/fa6";
import { LuWarehouse } from "react-icons/lu";
import { useLocation } from "react-router-dom";

export const Sidebar = () => {
  const { hasPermission } = useAuth();
  const departmentPolicy = useDepartmentPolicy();
  const userPolicy = useUserPolicy();
  const leadPolicy = useLeadPolicy();
  const contractPolicy = useContractPolicy();
  const taskPolicy = useTaskPolicy();
  const filePolicy = useFilePolicy();
  const dashboardPolicy = useDashboardPolicy();
  const warehousePolicy = useWarehousePolicy();
  const productPolicy = useProductPolicy();
  const billingPolicy = useBillingPolicy();
  const calculatorPolicy = useCalculatorPolicy();
  const installationPolicy = useInstallationPolicy();
  const auditPolicy = useAuditPolicy();
  const applicationPolicy = useApplicationPolicy();
  const clientPolicy = useClientPolicy();
  const location = useLocation();

  const defaultCalculatorSubmenu = {
    title: "Ustawienia",
    visible: calculatorPolicy.create(),
    to: routes.calculatorsSettings,
    icon: (props) => <Settings {...props} />,
  };

  const [calculatorSubmenu, setCalculatorSubmenu] = useState([defaultCalculatorSubmenu]);

  const { data: sidebar } = useQuery({
    queryKey: ["sidebar"],
    queryFn: apiClient.getSidebar,
    staleTime: 1000 * 60 * 5,
  });

  const {
    data: calculators,
    isLoading: isLoadingCalculators,
    error: errorCalculators,
  } = useQuery({
    queryKey: ["calculators"],
    queryFn: apiClient.getCalculators,
    staleTime: 1000 * 60 * 5,
    enabled: calculatorPolicy.viewAny(),
  });

  const menuItems = [
    {
      title: "Pulpit",
      visible: dashboardPolicy.view(),
      to: routes.dashboard,
      icon: (props) => <PieChart {...props} />,
    },
    {
      title: "Oddziały",
      visible: departmentPolicy.viewAny(),
      to: routes.departments,
      icon: (props) => <Users {...props} />,
    },
    {
      title: "Użytkownicy",
      visible: userPolicy.viewAny(),
      to: routes.users,
      icon: (props) => <User {...props} />,
    },
    {
      title: "Audyty",
      visible: auditPolicy.viewAny(),
      to: routes.audits,
      icon: (props) => <ClipboardList {...props} />,
      count: sidebar?.data?.audits_count,
    },
    {
      title: "Kontakty",
      visible: leadPolicy.viewAny(),
      to: routes.leads,
      icon: FaFilterCircleDollar,
      count:
        sidebar?.data?.expired_leads_count > 0 ? sidebar?.data?.expired_leads_count : sidebar?.data?.new_leads_count,
    },
    {
      title: "Kalkulatory",
      visible: (calculatorPolicy.viewAny() && calculators?.data?.length > 0) || calculatorPolicy.viewAll(),
      icon: (props) => <Calculator {...props} />,
      submenu: calculatorSubmenu,
      to: routes.calculators,
    },
    {
      title: "Montaże",
      visible: installationPolicy.viewAny(),
      to: routes.installations,
      icon: (props) => <Wrench {...props} />,
      count: sidebar?.data?.installations_count,
    },
    {
      title: "Wnioski",
      visible: applicationPolicy.viewAny(),
      to: routes.applications,
      icon: (props) => <FileUser {...props} />,
      count: sidebar?.data?.applications_count,
    },
    {
      title: "Umowy",
      visible: contractPolicy.viewAny(),
      to: routes.contracts,
      icon: (props) => <FileSignature {...props} />,
      count: sidebar?.data?.contracts_count,
    },
    {
      title: "Klienci",
      visible: clientPolicy.viewAny(),
      to: routes.clients,
      icon: (props) => <UserCheck {...props} />,
    },
    {
      title: "ERP",
      visible: warehousePolicy.viewAny() || productPolicy.viewAny(),
      to: routes.erp,
      icon: (props) => <Boxes {...props} />,
      count: sidebar?.data?.orders_count,
      submenu: [
        {
          title: "Magazyny",
          visible: warehousePolicy.viewAny(),
          to: routes.warehouses,
          icon: LuWarehouse,
        },
        {
          title: "Produkty",
          visible: productPolicy.viewAny(),
          to: routes.products,
          icon: (props) => <Package {...props} />,
        },
        {
          title: "Zamówienia",
          visible: warehousePolicy.viewAny(),
          to: routes.orders,
          icon: (props) => <PackageSearch {...props} />,
          count: sidebar?.data?.orders_count,
        },
      ],
    },
    {
      title: "Rozliczenia",
      visible: billingPolicy.viewAny(),
      to: routes.billings,
      icon: (props) => <CircleDollarSign {...props} />,
      count: sidebar?.data?.billings_count,
    },
    {
      title: "Zadania",
      visible: taskPolicy.viewAny(),
      to: routes.tasks,
      icon: (props) => <CircleCheck {...props} />,
      count: sidebar?.data?.tasks_count,
    },
    {
      title: "Pliki",
      visible: filePolicy.viewAny(),
      to: routes.files,
      icon: (props) => <Download {...props} />,
    },
    {
      title: "Kalendarz",
      visible: hasPermission("access_calendar"),
      to: routes.calendar,
      icon: (props) => <Calendar {...props} />,
    },
  ];

  const getActive = (item) => {
    let isActive = `/${location.pathname.split("/")[1]}` === item.to;
    if (item.submenu) {
      const pathname = location.pathname.split("/")[1];
      const index = `/${pathname}`.search(item.to ?? null);
      isActive = index >= 0;
    }
    return isActive;
  };

  useEffect(() => {
    if (calculators && !isLoadingCalculators && !errorCalculators) {
      setCalculatorSubmenu([
        defaultCalculatorSubmenu,
        ...calculators.data.map((calculator) => ({
          title: calculator.name,
          visible: calculatorPolicy.viewAny(),
          to: `${routes.calculators}/${calculator.id}`,
          icon: (props) => <Calculator {...props} />,
        })),
      ]);
    }
  }, [calculators, isLoadingCalculators, errorCalculators]);

  return (
    <>
      <DesktopMenu menuItems={menuItems} getActive={getActive} />
      <MobileMenu menuItems={menuItems} getActive={getActive} />
    </>
  );
};
