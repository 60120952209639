import { NotificationDropdown } from "@/components/Notifications/NotificationDropdown";
import { SiteLogo } from "@/components/SiteLogo";
import { UserAvatar } from "@/components/Users/UserAvatar";
import { MobileMenuLink } from "@/components/layout/Navbar/MobileMenuLink";
import { Card } from "@/components/ui/card";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import { ScrollArea } from "@/components/ui/scroll-area";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { LogOut, Menu } from "lucide-react";
import { Link } from "react-router-dom";

export const MobileMenu = ({ menuItems, getActive }) => {
  const { user, logOut } = useAuth();

  return (
    <nav className="flex lg:hidden flex-row justify-between items-center h-20 px-5 border-b">
      <SiteLogo className="h-10 cursor-pointer" linkTo={routes.dashboard} />
      <Drawer direction="left">
        <DrawerTrigger>
          <Menu />
        </DrawerTrigger>
        <DrawerContent className="w-full p-4 flex flex-col justify-between">
          <Card>
            <Link
              to={routes.account}
              className="flex justify-start items-center gap-3 border w-full hover:bg-accent p-3 rounded-md"
            >
              <UserAvatar user={user} />
              <div className="flex flex-col justify-center items-start">
                <p className="font-bold">{user.name}</p>
                <p className="text-sm leading-none text-muted-foreground">{user.email}</p>
              </div>
            </Link>
          </Card>
          <ScrollArea className="mt-5 pr-3 mb-auto">
            <div>
              <nav className="flex flex-col gap-2 justify-center items-start overflow-y-auto">
                {menuItems.map((menuItem, index) => {
                  return menuItem.visible ? (
                    <MobileMenuLink key={`menu_item_${index}`} menuItem={menuItem} getActive={getActive} />
                  ) : null;
                })}
              </nav>
            </div>
          </ScrollArea>
          <div className="flex-row gap-5 justify-center items-center">
            <NotificationDropdown />
            <a
              className="mt-3 w-full px-3 py-2 hover:bg-accent flex flex-row font-semibold items-center gap-2 rounded-md hover:cursor-pointer"
              onClick={logOut}
            >
              <LogOut className="h-6 w-6" />
              {i18n.t("Wyloguj")}
            </a>
          </div>
        </DrawerContent>
      </Drawer>
    </nav>
  );
};
