import { BillingSettingEditDialog } from "@/components/Billings/Settings/BillingSettingEditDialog";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import i18n from "@/i18n";
import { useBillingSettingPolicy } from "@/policies/billing/useBillingSettingPolicy";
import { routes } from "@/routes";
import { ArrowDown, ArrowUp, ArrowUpDown, MoreHorizontal, Pen, Trash } from "lucide-react";
import { Link } from "react-router-dom";

export const useBillingSettingsTableColumns = (handleDelete) => {
  const billingSettingPolicy = useBillingSettingPolicy();

  const columns = [
    {
      id: "name",
      name: "Nazwa",
      accessorKey: "name",
      header: ({ column }) => {
        return (
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}>
            {i18n.t("Nazwa")}
            {column.getIsSorted() === "asc" ? (
              <ArrowDown className="h-4 w-4" />
            ) : column.getIsSorted() === "desc" ? (
              <ArrowUp className="h-4 w-4" />
            ) : (
              <ArrowUpDown className="h-4 w-4" />
            )}
          </Button>
        );
      },
      cell: ({ row }) => {
        if (billingSettingPolicy.view()) {
          return (
            <Link
              to={`${routes.billingSettings}/${row.original.id}`}
              className="flex flex-row items-center gap-3 font-medium hover:underline"
            >
              {row.getValue("name")}
            </Link>
          );
        } else {
          return <span>{row.getValue("name")}</span>;
        }
      },
    },
    {
      id: "rules_count",
      name: "Liczba reguł",
      accessorKey: "rules_count",
      header: i18n.t("Liczba reguł"),
      cell: ({ row }) => row.getValue("rules_count"),
    },
    {
      id: "actions",
      enableHiding: false,
      cell: ({ row }) => {
        const billingSetting = row.original;
        if (!billingSettingPolicy.destroy() && !billingSettingPolicy.update()) return null;

        return (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" className="h-8 w-8 p-0" leftIcon={<MoreHorizontal className="h-4 w-4" />} />
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {billingSettingPolicy.update() && (
                <BillingSettingEditDialog
                  trigger={
                    <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                      <Pen size={16} className="mr-1 text-primary" />
                      <span className="font-semibold">{i18n.t("Edytuj")}</span>
                    </DropdownMenuItem>
                  }
                  billingSetting={billingSetting}
                />
              )}
              {billingSettingPolicy.destroy() && (
                <>
                  <DropdownMenuSeparator />
                  <DeleteAlertDialog
                    onConfirm={() => handleDelete(billingSetting.id)}
                    message="Czy na pewno chcesz usunąć to rozliczenie? Tej operacji nie można cofnąć"
                    trigger={
                      <DropdownMenuItem onSelect={(e) => e.preventDefault()}>
                        <Trash size={16} className="mr-1 text-primary" />
                        <span className="font-semibold">{i18n.t("Usuń")}</span>
                      </DropdownMenuItem>
                    }
                  />
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        );
      },
    },
  ];
  return { columns };
};
