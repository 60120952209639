import { LeadStatusCreateDialog } from "@/components/Leads/Settings/Statuses/LeadStatusCreateDialog";
import { LeadStatuses } from "@/components/Leads/Settings/Statuses/LeadStatuses";
import { LeadSources } from "@/components/Leads/Sources/LeadSources";
import { ProcessCreateDialog } from "@/components/Process/ProcessCreateDialog";
import { ProcessSettings } from "@/components/Process/ProcessSettings";
import i18n from "@/i18n";
import { useLeadSourcePolicy } from "@/policies/lead/useLeadSourcePolicy";
import { useLeadStatusPolicy } from "@/policies/lead/useLeadStatusPolicy";
import { useProcessPolicy } from "@/policies/useProcessPolicy";

export const LeadSettings = () => {
  const leadSourcePolicy = useLeadSourcePolicy();
  const leadStatusPolicy = useLeadStatusPolicy();
  const processPolicy = useProcessPolicy();

  return (
    <div className="flex flex-col gap-8 w-full">
      {leadStatusPolicy.update() && (
        <div>
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <LeadStatusCreateDialog />
          </div>
          <LeadStatuses />
        </div>
      )}
      {leadSourcePolicy.update() && (
        <div>
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Źródła")}</h2>
          </div>
          <LeadSources />
        </div>
      )}
      {processPolicy.create() && (
        <div>
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Lead" />
          </div>
          <ProcessSettings model="Lead" />
        </div>
      )}
    </div>
  );
};
