import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { getAvatarUrl } from "@/helpers/getAvatarUrl";

export const UserAvatar = ({ user, ...rest }) => {
  if (!user || !user.avatar) {
    return (
      <Avatar {...rest}>
        <AvatarFallback />
      </Avatar>
    );
  }

  return (
    <Avatar {...rest}>
      <AvatarImage src={getAvatarUrl(user)} alt="avatar" />
      <AvatarFallback />
    </Avatar>
  );
};
