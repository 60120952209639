import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { UsersTable } from "@/components/Users/UsersTable";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { routes } from "@/routes";
import { Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const UsersPage = () => {
  useDocumentTitle("Użytkownicy");
  const navigate = useNavigate();
  const userPolicy = useUserPolicy();

  const handleCreate = () => {
    return navigate(routes.userCreate);
  };

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {userPolicy.create() && (
            <Button variant="outline" title="Dodaj użytkownika" leftIcon={<Plus size={20} />} onClick={handleCreate} />
          )}
        </div>
      </div>
      <UsersTable />
    </Layout>
  );
};
