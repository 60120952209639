import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { ThemeToggle } from "@/components/layout/ThemeToggle";
import { SiteLogo } from "@/components/SiteLogo";
import { Text } from "@/components/Text";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { useToast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import i18n from "@/i18n";
import { routes } from "@/routes";
import { yupResolver } from "@hookform/resolvers/yup";
import { ChevronLeft } from "lucide-react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  email: Yup.string().required(i18n.t("Pole jest wymagane")).email("Pole musi być poprawnym adresem email"),
});

export const ForgotPasswordPage = () => {
  useDocumentTitle("Odzyskiwanie hasła");
  const { toast } = useToast();
  const { user } = useAuth();
  const navigate = useNavigate();

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { email: "" },
    mode: "onBlur",
  });

  const onSubmit = async (data) => {
    const response = await apiClient.forgotPassword(data);
    if (response.ok) {
      form.reset();
      toast({ title: <SuccessToast title="Link został wysłany na podany adres email" /> });
    } else {
      toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
    }
  };

  useEffect(() => {
    if (user) return navigate(routes.dashboard);
  }, [user]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <SiteLogo className="mx-auto w-1/2" />
      </div>
      <Card className="w-full max-w-sm mt-8">
        <CardHeader>
          <CardTitle className="text-xl">{i18n.t("Odzyskiwanie hasła")}</CardTitle>
          <CardDescription>
            {i18n.t("Na podany adres zostanie wysłany e-mail z linkiem do zresetowania hasła i dalszymi instrukcjami.")}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <Form form={form} onSubmit={onSubmit}>
            <div className="grid gap-4">
              <FormField name="email" label="Email" type="email" />
              <Button
                className="w-full mt-3"
                isLoading={form.formState.isSubmitting}
                title="Zresetuj hasło"
                type="submit"
              />
            </div>
          </Form>
        </CardContent>
        <CardFooter className="flex flex-col items-center justify-center">
          <Link
            to={routes.login}
            className="flex flex-row items-center justify-center text-sm font-semibold text-primary text-center hover:text-primary/80"
          >
            <ChevronLeft size={16} />
            <Text translate="Powrót" />
          </Link>
        </CardFooter>
      </Card>
      <ThemeToggle className="mt-3" />
    </div>
  );
};
