import apiClient from "@/api/apiClient";
import { ApplicationDetails } from "@/components/Applications/ApplicationDetails/ApplicationDetails";
import { ApplicationFileCreateDialog } from "@/components/Applications/ApplicationDetails/Files/ApplicationFileCreateDialog";
import { ApplicationEditDialog } from "@/components/Applications/ApplicationEditDialog";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { Layout } from "@/components/layout/Layout";
import { TaskCreateDialog } from "@/components/Tasks/TaskCreateDialog";
import { ButtonContext } from "@/context/ButtonContext";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useApplicationPolicy } from "@/policies/application/useApplicationPolicy";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Pen, Trash } from "lucide-react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ApplicationDetailsPage = () => {
  const { id: applicationId } = useParams();
  const navigate = useNavigate();
  const { buttons } = useContext(ButtonContext);
  const applicationPolicy = useApplicationPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["application", applicationId],
    queryFn: () => apiClient.getApplication(applicationId),
  });
  useDocumentTitle(response?.data ? `Wniosek ${response.data?.lead?.name}` : "Wniosek");

  const deleteApplicationMutation = useMutation({
    mutationFn: apiClient.deleteApplication,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["applications"] });
      queryClient.invalidateQueries({ queryKey: ["sidebar"] });
      if (res.ok) {
        return navigate(routes.applications);
      }
    },
  });

  const handleDelete = () => {
    deleteApplicationMutation.mutate(applicationId);
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.lead?.name !== " " ? response?.data?.lead?.name : "Brak"} />
        <div
          id="application_buttons"
          className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5"
        >
          {buttons["addApplicationFiles"] && <ApplicationFileCreateDialog />}
          <TaskCreateDialog
            taskableId={applicationId}
            taskableType="Application"
            withUser={true}
            buttonVariant="outline"
          />
          <ApplicationEditDialog
            application={response?.data}
            trigger={<Button variant="outline" title="Edytuj wniosek" leftIcon={<Pen size={20} />} />}
          />
          {applicationPolicy.destroy(response?.data) && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="destructive"
                  title="Usuń wniosek"
                  isLoading={deleteApplicationMutation.isPending}
                  leftIcon={<Trash size={20} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć ten wniosek? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <ApplicationDetails application={response?.data} />
    </Layout>
  );
};
