import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { EmptyState } from "@/components/EmptyState";
import { Input } from "@/components/forms/Input";
import { Loader } from "@/components/Loader";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
// import { useContractMessagePolicy } from "@/policies/contracts/useContractMessagePolicy";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { format, parse } from "date-fns";
import { Send } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

export const ContractMessagesCard = () => {
  const { id: contractId } = useParams();
  const { user } = useAuth();
  const messagesContainerRef = useRef(null);
  // const contractMessagePolicy = useContractMessagePolicy();
  const [content, setContent] = useState("");

  const queryClient = useQueryClient();
  const { isLoading, data: response } = useQuery({
    queryKey: ["contract", contractId, "messages"],
    queryFn: () => apiClient.getContractMessages(contractId),
  });

  // const deleteContractMessageMutation = useMutation({mutationFn: apiClient.deleteContractMessage,
  //   onSuccess: () => {
  //     queryClient.invalidateQueries("contractMessages");
  //   },
  // });

  // const updateContractMessageMutation = useMutation({mutationFn: apiClient.updateContractMessage,
  //   onSuccess: () => {
  //     queryClient.invalidateQueries("contractMessages");
  //   },
  // });

  const createContractMessageMutation = useMutation({
    mutationFn: apiClient.createContractMessage,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["contract", contractId, "messages"] });
        setContent("");
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  // const handleEdit = (messageId, content) => {
  //   updateContractMessageMutation.mutate({ contractId, messageId, content });
  // };

  // const handleDelete = (messageId) => {
  //   deleteContractMessageMutation.mutate({ contractId, messageId });
  // };

  const handleCreate = (e) => {
    e.preventDefault();
    if (content.length > 0) createContractMessageMutation.mutate({ contractId, content });
  };

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTo({
        top: messagesContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (response?.data) {
      scrollToBottom();
    }
  }, [response?.data]);

  return (
    <Card className="flex-1 h-fit shadow-md pr-2">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Konwersacja")}</CardTitle>
          <CardDescription>{i18n.t("Konwersacja na temat tej umowy")}</CardDescription>
        </div>
      </CardHeader>
      {/* <ScrollArea className="w-full flex max-h-96 flex-col overflow-y-auto" ref={messagesContainerRef}> */}
      <div
        ref={messagesContainerRef}
        className="w-full max-h-96 overflow-y-auto px-4" // Ustawiono overflow na auto i padding dla lepszej widoczności
      >
        <CardContent>
          {isLoading && !response ? (
            <Loader />
          ) : (
            <>
              {!response.data.length && <EmptyState title="Brak wiadomości" />}
              <div className="space-y-4">
                {response?.data.map((message, index) => (
                  <div className="flex flex-col" key={index}>
                    <p
                      className={cn(
                        "text-xs text-muted-foreground",
                        message.user.id === user.id ? "ml-auto mr-1" : " mr-auto ml-1",
                      )}
                    >
                      {message.user.id === user.id ? "Ja" : message.user.name}
                    </p>
                    <div
                      className={cn(
                        "flex flex-row w-max max-w-[75%] gap-2 rounded-lg px-3 py-2 text-sm",
                        message.user.id === user.id
                          ? "ml-auto bg-primary text-primary-foreground dark:text-foreground"
                          : "bg-muted",
                      )}
                    >
                      {message.content}
                      {/* <TooltipProvider delayDuration={200}>
                  <Tooltip>
                    {message.user.id === user.id ? (
                      <TooltipTrigger asChild>
                        <div>{message.content}</div>
                      </TooltipTrigger>
                    ) : (
                      message.content
                    )}
                    <TooltipContent>
                      <div className="flex flex-row gap-1 justify-end items-center">
                        <Button variant="ghost" className="flex flex-row gap-1 items-center">
                          <Pencil size={16} />
                          {i18n.t("Edytuj")}
                        </Button>
                        <Separator orientation="vertical" className="h-8" />
                        <Button variant="ghost" className="flex flex-row gap-1 items-center">
                          <Trash className="text-destructive" size={16} />
                          {i18n.t("Usuń")}
                        </Button>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider> */}
                    </div>
                    <p
                      className={cn(
                        "text-xs text-muted-foreground opacity-50",
                        message.user.id === user.id ? "ml-auto mr-1" : " mr-auto ml-1",
                      )}
                    >
                      {format(parse(message.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy HH:mm")}
                    </p>
                  </div>
                ))}
              </div>
            </>
          )}
        </CardContent>
        {/* </ScrollArea> */}
      </div>
      <CardFooter>
        <form onSubmit={handleCreate} className="flex w-full items-center space-x-2">
          <Input
            name="message"
            placeholder="Napisz wiadomość..."
            className="flex-1"
            autoComplete="off"
            value={content}
            onChange={(event) => setContent(event.target.value)}
          />
          <Button type="submit" size="icon" disabled={content.length === 0} leftIcon={<Send className="h-4 w-4" />} />
        </form>
      </CardFooter>
    </Card>
  );
};
