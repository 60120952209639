import { Button } from "@/components/Button";
import { ClickAwayListener } from "@/components/ClickAwayListener";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { Card, CardContent, CardFooter, CardHeader } from "@/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Textarea } from "@/components/ui/textarea";
import { UserTooltip } from "@/components/Users/UserTooltip";
import i18n from "@/i18n";
import { format, parse } from "date-fns";
import { MoreHorizontal, Pen, Trash } from "lucide-react";
import { useState } from "react";

export const Note = ({ note, handleDelete, handleEdit, canDelete = false, canEdit = false }) => {
  const [newNote, setNewNote] = useState(note && note.note);
  const [editMode, setEditMode] = useState(false);

  const handleSave = () => {
    setEditMode(false);
    if (note.note !== newNote) handleEdit(note.id, { note: newNote });
  };

  return (
    <Card className="w-full">
      <CardHeader className="font-semibold leading-none tracking-tight flex flex-row justify-between items-center py-2">
        <UserTooltip user={note.user} linkClassName="py-2 m-0" />
        {(canEdit || canDelete) && (
          <DropdownMenu>
            <DropdownMenuTrigger>
              <Button variant="ghost" className="h-8 w-8 p-0 text-foreground">
                <span className="sr-only">{i18n.t("Otwórz menu")}</span>
                <MoreHorizontal className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {canEdit && (
                <>
                  <DropdownMenuItem
                    className="flex flex-row gap-2 font-semibold text-sm"
                    onClick={() => setEditMode(!editMode)}
                  >
                    <Pen size={20} className="text-primary" />
                    {i18n.t("Edytuj")}
                  </DropdownMenuItem>
                  <DropdownMenuSeparator />
                </>
              )}
              {canDelete && (
                <>
                  <DropdownMenuItem
                    className="flex flex-row gap-2 font-semibold text-sm"
                    onClick={(e) => e.preventDefault()}
                  >
                    <DeleteAlertDialog
                      message="Czy na pewno chcesz usunąć tę notatkę? Tej operacji nie można cofnąć"
                      onConfirm={() => handleDelete(note.id)}
                      trigger={
                        <Button
                          variant="ghost"
                          size="xs"
                          title="Usuń"
                          leftIcon={<Trash className="text-primary" size={20} />}
                        />
                      }
                    />
                  </DropdownMenuItem>
                </>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </CardHeader>
      <CardContent className="text-sm text-muted-foreground" onDoubleClick={() => canEdit && setEditMode(true)}>
        {editMode ? (
          <ClickAwayListener onClickAway={handleSave}>
            <Textarea className="p-0 min-h-40" value={newNote} onChange={(e) => setNewNote(e.target.value)} />
          </ClickAwayListener>
        ) : (
          <p>{note.note}</p>
        )}
      </CardContent>
      <CardFooter>
        <p className="text-sm text-muted-foreground">
          {format(parse(note.created_at, "yyyy-MM-dd HH:mm:ss", new Date()), "dd MMM yyyy HH:mm:ss")}
        </p>
      </CardFooter>
    </Card>
  );
};
